/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import { Modal } from "reactstrap";
import "./styles.scss";
import { GiAlarmClock } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { TiTick } from "react-icons/ti";
import {
  selectSelectedFood,
  selectModifiersInCategory,
  selectModifiersDetails,
  selectRecomendedFoods,
  // selectPromotions,
  selectTaxIdsInCategory,
  selectTaxesInDetails,
  selectSelectedStore,
} from "../../pages/menuPage/selectors";
import { useSelector, useDispatch } from "react-redux";
import { useMenuSlice } from "../../pages/menuPage/actions";
import { mainActions } from "../../pages/main/actions";
import {
  selectRestaurantCurrency,
  // selectCurrentPromotions,
  // selectCartData
} from "../../pages/main/selectors";
import {
  calculatePriceRange,
  formatPriceRange,
} from "../../pages/menuPage/utils";
import configs from "../../configs";
import {
  // calculateDiscountOnFoodItem,
  calculateTaxOnFoodItem,
  calculateTotalOnFoodItem,
  // getPromotionsDiscount,
  rearrangeBoughtItemsAndTotal,
  // calculateDiscountOnCart,
} from "./functions";
import i18next from "i18next";
import ModifierCard from "./components/ModifierCard";
import PriceVariantCard from "./components/PriceVariantCard";
import ModifierGroupHeader from "./components/ModifierGroupHeader";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import FoodCard from "../FoodCard";
import { MdOutlineFastfood } from "react-icons/md";
import { FaWeightHanging } from "react-icons/fa";
import images from "../../images";
import sampleFoodImage from "../../images/sample-food.png";
import { CART_ITEM_PROPERTIES, DINE_IN_MODES } from "./constants";
// import MarkDown from '../MarkDown';

export default function FoodDescriptionModal(props) {
  const { enableOrdering } = props;
  const food = useSelector(selectSelectedFood);
  const [modalOpen, setModalOpen] = useState(false);
  const modifiersInCategory = useSelector(selectModifiersInCategory);
  const taxIdsInCategory = useSelector(selectTaxIdsInCategory);
  const modifiersDetails = useSelector(selectModifiersDetails);
  const recomendedFoods = useSelector(selectRecomendedFoods);
  const currency = useSelector(selectRestaurantCurrency);
  const taxesInDetails = useSelector(selectTaxesInDetails);
  const selectedStore = useSelector(selectSelectedStore);
  // const promotions = useSelector(selectCurrentPromotions);
  // const existingCartData = useSelector(selectCartData);
  const [foodCount, setFoodCount] = useState(0);
  const [temp_foodCount, setTempFoodCount] = useState(1);
  const [instructions, setInstructions] = useState("");
  const [addButtonClicked, setAddButtonClicked] = useState(false);
  const [initialToggler, setInitialToggler] = useState(false);
  const [itemsBought, setItemsBought] = useState({
    total: 0,
    sub_total: 0,
    discount: 0,
    tax_not_included: 0,
    tax_included: 0,
    promotions: [],
  });
  const [selectModifiersGroup, setSelectModifiersGroup] = useState({});
  // const [priceVariant, setPriceVariant] = useState(
  //   (food?.priceVariants && food?.priceVariants?.length) ? food.priceVariants?.[0]?._id : '',
  // );
  const [priceVariant, setPriceVariant] = useState(
    food?.priceVariants && food?.priceVariants?.length
      ? food.priceVariants?.[0]
      : null
  );

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useMenuSlice();

  const getModifiers = useCallback(() => {
    if (food) {
      const modifierIdArrays = Array.from(
        new Set([
          ...(food.modifierGroups ? food.modifierGroups : []),
          ...(modifiersInCategory.length > 0 ? modifiersInCategory[0] : []),
        ])
      );
      if (modifierIdArrays.length > 0) {
        dispatch(actions.getMdifiers(modifierIdArrays));
      } else {
        dispatch(actions.cleanModifiers());
      }
    }
  }, [food, modifiersInCategory]);

  const getTaxCategories = useCallback(() => {
    if (food) {
      const taxesIdArray = Array.from(
        new Set([
          ...(food.tax_categories ? food.tax_categories : []),
          ...(taxIdsInCategory.length > 0 ? taxIdsInCategory[0] : []),
        ])
      );
      if (taxesIdArray.length > 0) {
        dispatch(actions.getTaxes(taxesIdArray));
      } else {
        dispatch(actions.cleanTaxes());
      }
    }
  }, [food, taxIdsInCategory]);

  useEffect(() => {
    if (food) {
      setModalOpen(true);
      setItemsBought({
        total: 0,
        sub_total: 0,
        discount: 0,
        tax_not_included: 0,
        tax_included: 0,
      });
      getModifiers();
      getTaxCategories();
      setFoodCount(1);
      setInstructions("");
      setInitialToggler(!initialToggler);
      dispatch(actions.getPromotions({ id: food._id }));
      if (food.recommendedFoodsIds.length > 0) {
        dispatch(actions.getRecomendedFoods({ ids: food.recommendedFoodsIds }));
      } else {
        dispatch(actions.clearRecomendedFoods());
      }
    } else {
      setModalOpen(false);
    }
  }, [food, getModifiers]);

  useEffect(() => {
    if (food && foodCount > 0) {
      const price =
        food?.priceVariants && food?.priceVariants?.length >= 2
          ? food?.priceVariants?.find(
              (variant) => variant?._id === priceVariant?._id
            )?.price
          : food?.priceVariants?.length === 1
          ? food?.priceVariants?.[0]?.price
          : food?.price;
      // const _discount = calculateDiscountOnFoodItem({
      //   food: food,
      //   promotions: promotions,
      //   sub_total: price * foodCount,
      // });

      //need to remove this
      const { tax_included, tax_not_included } = calculateTaxOnFoodItem({
        taxes: taxesInDetails,
        price: price * foodCount,
        dine_mode: DINE_IN_MODES.DINE_IN,
      });

      const _sub_total = calculateTotalOnFoodItem({
        food,
        count: foodCount,
        price,
      });
      const _temp = rearrangeBoughtItemsAndTotal(itemsBought, {
        [CART_ITEM_PROPERTIES.FOOD]: {
          ...food,
          id: food._id,
          count: foodCount,
          priceVariant,
          discount: 0.0,
          // promotions:_discountDetails.promotions,
          tax_included,
          tax_not_included,
          total: _sub_total + tax_not_included,
          sub_total: _sub_total,
          taxes: taxesInDetails, // added taxes data to food object
        },
      });
      setItemsBought({ ..._temp });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodCount, initialToggler, taxesInDetails, priceVariant]);

  const toggleModal = () => {
    dispatch(actions.setSelectedFood(null));
  };

  const addModifierToBoughtList = ({ modifier, count }) => {
    // const _discount = calculateDiscountOnFoodItem({ food, count, promotions });
    const _discount = 0.0;

    // const _tax = calculateTaxOnFoodItem();
    const _sub_total = calculateTotalOnFoodItem({
      food: modifier,
      count: count,
      price: modifier.price,
    });

    setItemsBought({
      ...rearrangeBoughtItemsAndTotal(itemsBought, {
        [CART_ITEM_PROPERTIES.MODIFIERS]: {
          ...modifier,
          count: count,
          discount: _discount,
          tax_included: 0,
          tax_not_included: 0,
          total: _sub_total,
          sub_total: _sub_total,
          taxes: taxesInDetails, // added taxes data to food object
        },
      }),
    });
  };

  useEffect(() => {
    if (instructions !== "") {
      setItemsBought({
        ...itemsBought,
        instructions,
      });
    }
  }, [instructions]);

  const addToCart = () => {
    if (!addButtonClicked) {
      let initialError = {};
      // eslint-disable-next-line array-callback-return
      modifiersDetails?.map((_item) => {
        initialError = {
          ...initialError,
          [_item._id]: {
            count: selectModifiersGroup?.[_item._id]?.count || 0,
            error: checkModifireValidity(
              selectModifiersGroup?.[_item._id]?.count || 0,
              _item
            ),
          },
        };
      });
      setSelectModifiersGroup(initialError);

      const errorObjects = Object.keys(initialError).filter(
        (_item) => initialError?.[_item]?.error !== null
      );
      if (errorObjects.length <= 0) {
        setAddButtonClicked(true);

        const itemsBoughtModified = {
          ...itemsBought,
        };
        if (taxesInDetails.length > 0) {
          const externalPosTaxIds = taxesInDetails.reduce(
            (externalPosTaxIds, currTax) => {
              if (currTax?.externalPosTaxId) {
                if (!externalPosTaxIds?.externalPosTaxId?.type) {
                  externalPosTaxIds.type = currTax?.externalPosTaxId?.type;
                  externalPosTaxIds.ids.push(currTax?.externalPosTaxId?.id);
                }
              }

              return externalPosTaxIds;
            },
            { type: "", ids: [] }
          );

          if (externalPosTaxIds.type && externalPosTaxIds.ids.length > 0) {
            itemsBoughtModified.externalPosTaxIds = externalPosTaxIds;
          }
        }

        itemsBoughtModified.selectedStoreId = selectedStore?._id;
        itemsBoughtModified.miniAppId =
          selectedStore?.menuId?.externalPosStoreId?.miniAppId;
        dispatch(mainActions.addCartData(itemsBoughtModified));
        toggleModal();

        setTimeout(() => {
          toggleModal();
          setAddButtonClicked(false);
        }, 100);
      }
    }
  };

  const handlePriceVariantSelect = (variantId) => {
    const priceVariant = food?.priceVariants.find(
      (variant) => variant?._id === variantId
    );
    setPriceVariant(priceVariant);
  };

  const checkModifireValidity = (groupCount, modifierGroup) => {
    let error = null;
    if (modifierGroup?.modifierType === "required") {
      const max = modifierGroup?.forceMaximum;
      const min = modifierGroup?.forceMinimum;
      if (groupCount < min) {
        error = {
          ...error,
          message: `${t("food_description.modifier_required_min_error", {
            modifierGroupName:
              modifierGroup.name[i18next.language] || modifierGroup.name["en"],
            minCount: min,
          })}`,
        };
      } else if (groupCount > max) {
        error = {
          ...error,
          message: `${t("food_description.modifier_required_max_error", {
            modifierGroupName:
              modifierGroup.name[i18next.language] || modifierGroup.name["en"],
            maxCount: max,
          })}`,
        };
      }
    }
    return error;
  };
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 0 },
      items: 1,
      partialVisibilityGutter: 40,
    },
  };

  return (
    <Modal
      isOpen={modalOpen}
      className="foodDescription-modal-wrapper"
      id="food_description_modal_id"
      toggle={() => toggleModal()}
    >
      {food && (
        <>
          <Carousel
            responsive={responsive}
            className="fooddescription-carousel"
            autoPlay={true}
            arrows={false}
            infinite={false}
            showDots={true}
          >
            {food.imageUrls?.length > 0 ? (
              food.imageUrls.map((imageUrl, index) => (
                <img
                  key={index}
                  src={configs.RESOURCES_BUCKET_URL + "/" + imageUrl}
                  alt=""
                />
              ))
            ) : (
              <img src={sampleFoodImage} alt="" />
            )}
          </Carousel>
          <div className="header-close">
            <div />
            <AiOutlineClose className="icon" onClick={() => toggleModal()} />
          </div>
          <div className="content">
            <div className="header">
              <div className="name-price">
                <h4 className="price">
                  <strong>
                    {`${currency} `}
                    {!food?.priceVariants?.length
                      ? Number.parseFloat(food?.price).toFixed(2)
                      : food?.priceVariants?.length === 1
                      ? Number.parseFloat(
                          food?.priceVariants?.[0]?.price
                        ).toFixed(2)
                      : formatPriceRange(
                          calculatePriceRange(food?.priceVariants)
                        )}
                  </strong>{" "}
                </h4>
                <p className="name">{food.name?.[i18next.language || "en"]}</p>
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: food.description?.[i18next.language || "en"],
                }}
              ></div>
            </div>
            <hr />
            <div className="tab-content">
              {food.ingredients && food.ingredients.length > 0 && (
                <div className="ingredients">
                  <MdOutlineFastfood className="icon" />
                  <h6>{t("food_description.ingredients")}</h6>
                  <div className="contents">
                    {food.ingredients.map((_item, index) => (
                      <p key={index}>{t(`ingredients.${_item}`)}</p>
                    ))}
                  </div>
                </div>
              )}
              <div className="duration-weight">
                <div className="properties-container">
                  {food?.properties?.map((proptery, index) => {
                    return <img src={images[proptery]} alt="" key={index} />;
                  })}
                </div>
                {food.preparationTime && (
                  <div className="specs">
                    <GiAlarmClock className="icon" />
                    <p>
                      {food.preparationTime} {t("food_description.mins")}
                    </p>
                  </div>
                )}
                {food.size && (
                  <div className="specs">
                    <FaWeightHanging className="icon" />
                    <p>
                      {food.size} {food.unit}
                    </p>
                  </div>
                )}
              </div>

              {/* Show multiple price variants if there are two or more than two price variants */}
              {food?.priceVariants && food?.priceVariants?.length >= 2 ? (
                <>
                  <ModifierGroupHeader
                    name={t("food_description.Variants")}
                    type="some type"
                  />
                  {food.priceVariants.map((variant, idx) => (
                    <PriceVariantCard
                      key={`${idx}-${variant?.name}`}
                      defaultVariant={priceVariant}
                      variant={variant}
                      currency={currency}
                      onPriceVariantSelect={handlePriceVariantSelect}
                    />
                  ))}
                </>
              ) : null}

              {modifiersDetails && modifiersDetails.length > 0 && (
                <>
                  {modifiersDetails.map((_item, index) => (
                    <div
                      key={index}
                      className={`modifire_group_container ${
                        selectModifiersGroup?.[_item._id]?.error
                          ? "modifire_group_error"
                          : ""
                      } ${!enableOrdering ? "dissable" : ""}`}
                    >
                      <ModifierGroupHeader
                        name={
                          _item.name[i18next.language || "en"] ||
                          _item.name["en"]
                        }
                        type={_item.modifierType}
                      />
                      {_item.modifires?.map((__item, __index) => (
                        <>
                          {__item.enable && (
                            <ModifierCard
                              key={`${__index} + ${index + 1}`}
                              count={
                                itemsBought[
                                  CART_ITEM_PROPERTIES.MODIFIERS
                                ]?.items.find((item) => item._id === __item._id)
                                  ?.count || 0
                              }
                              groupCount={
                                selectModifiersGroup[_item._id]?.count || 0
                              }
                              name={
                                __item.name[i18next.language || "en"] ||
                                _item.name["en"]
                              }
                              price={Number.parseFloat(__item.price).toFixed(2)}
                              unit={__item.unit}
                              currency={currency}
                              isMultipleItems={_item?.multipleItems}
                              setGroupCount={(groupCount) => {
                                setSelectModifiersGroup({
                                  ...selectModifiersGroup,
                                  [_item._id]: {
                                    count: groupCount,
                                    error: checkModifireValidity(
                                      groupCount,
                                      _item
                                    ),
                                  },
                                });
                              }}
                              setCount={(count) => {
                                addModifierToBoughtList({
                                  modifier: __item,
                                  count,
                                });
                              }}
                            />
                          )}
                        </>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </div>
            {enableOrdering && (
              <div className="special-contents">
                <h4>{t("food_description.special-instructions")}</h4>
                <p>{t("food_description.allergic-or-omit-ingredients")}</p>
                <textarea
                  maxLength={50}
                  value={instructions}
                  onChange={(e) => setInstructions(e.target.value)}
                />
              </div>
            )}
            {recomendedFoods?.length > 0 && (
              <div className="recomnded-section">
                <h6>{t("food_description.also_try")}</h6>
                <Carousel
                  responsive={responsive}
                  className="fooddescription--recomended-carousel"
                  autoPlay={true}
                  arrows={true}
                  infinite={true}
                  partialVisbile={true}
                  showDots={false}
                >
                  {recomendedFoods.map((food, index) => (
                    <FoodCard data={food} key={index} />
                  ))}
                </Carousel>
              </div>
            )}
            {enableOrdering && (
              <div className="button-container">
                <div>
                  <button
                    className="primary"
                    onClick={() => {
                      if (foodCount > 1) {
                        setTempFoodCount(foodCount - 1);
                        setFoodCount(foodCount - 1);
                      }
                    }}
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={temp_foodCount}
                    onBlur={() => {
                      if (temp_foodCount === "") {
                        setTempFoodCount(foodCount);
                      }
                    }}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      if (
                        /^\d+$/.test(inputValue) &&
                        parseInt(inputValue) > 0
                      ) {
                        setTempFoodCount(parseInt(inputValue));
                        setFoodCount(parseInt(inputValue));
                      } else if (inputValue === "") {
                        setTempFoodCount(inputValue);
                      }
                    }}
                  />
                  {/* <h6>{foodCount}</h6> */}
                  <button
                    className="primary"
                    onClick={() => {
                      setTempFoodCount(foodCount + 1);
                      setFoodCount(foodCount + 1);
                    }}
                  >
                    +
                  </button>
                </div>
              </div>
            )}
          </div>

          <div className="food_footer">
            {Object.keys(selectModifiersGroup).map((item, index) => {
              return (
                selectModifiersGroup?.[item]?.error && (
                  <div className="footer-error" key={index}>
                    {selectModifiersGroup?.[item]?.error?.message}
                  </div>
                )
              );
            })}

            {enableOrdering && (
              <button
                className={`main-button add_to_cart_button secondary ${
                  addButtonClicked ? "clicked" : ""
                }`}
                disabled={!enableOrdering}
                onClick={() => addToCart()}
              >
                <span>
                  {" "}
                  {addButtonClicked ? (
                    <TiTick className="icon" />
                  ) : (
                    `${t("food_description.add_to_cart", {
                      count: foodCount,
                      currency,
                      amountPrice: Number.parseFloat(
                        Math.round(itemsBought.sub_total * 100) / 100
                      ).toFixed(2),
                    })}`
                  )}
                </span>
              </button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
}
